import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { RichText } from "prismic-reactjs";
import { graphql, Link } from "gatsby";
import styled from "@emotion/styled";
import colors from "styles/colors";
import dimensions from "styles/dimensions";
import About from "components/About";
import Layout from "components/Layout";
import ProjectCard from "components/ProjectCard";
import PostCard from "components/PostCard";
import Button from "components/_ui/Button";

const HomeHeroContainer = styled("section")`
    margin-top: 5.5em;
    max-width: ${dimensions.maxwidthDesktop}px;
    margin-left: auto;
    margin-right: auto;

    div {
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-top: -5em;
        margin-left: -50vw;
        margin-right: -50vw;
    }
  
    img {
        max-width: 100%;
    }

    @media(max-width:${dimensions.maxwidthMobile}px) {
        margin-bottom: 3em;
     }
`

const Section = styled("div")`
    margin-bottom: 5em;
    display: flex;
    flex-direction: column;

    @media(max-width:${dimensions.maxwidthTablet}px) {
        margin-bottom: 4em;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    h1 {
        text-align: left;
        a {
            text-decoration: none;
            transition: all 100ms ease-in-out;

            &:nth-of-type(1) { color: ${colors.violet}; }
            &:nth-of-type(2) { color: ${colors.red}; }
            &:nth-of-type(3) { color: ${colors.green}; }
            &:nth-of-type(4) { color: ${colors.blue}; }
            &:nth-of-type(5) { color: ${colors.yellow}; }

            &:hover {
                cursor: pointer;
                transition: all 100ms ease-in-out;

                &:nth-of-type(1) { color: ${colors.blue600};    background-color: ${colors.blue200};}
                &:nth-of-type(2) { color: ${colors.orange600};  background-color: ${colors.orange200};}
                &:nth-of-type(3) { color: ${colors.purple600};  background-color: ${colors.purple200};}
                &:nth-of-type(4) { color: ${colors.green600};   background-color: ${colors.green200};}
                &:nth-of-type(5) { color: ${colors.teal600};    background-color: ${colors.teal200};}

            }
        }
    }

    &.welcome {
        padding: 0em 4em 2em 4em;
        background: ${colors.light}; 
        position: relative;
        margin-top: -9em;

        @media(max-width:${dimensions.maxwidthTablet}px) {
            margin-top: 0;
            padding: 0;
            background: none;
        }
    }

    .joinButton {
        text-align:center;
        button {
            background: ${colors.red};
        }
    }
`

const WorkAction = styled(Link)`
    font-weight: 600;
    text-decoration: none;
    color: currentColor;
    transition: all 150ms ease-in-out;
    margin-left: auto;

    @media(max-width:${dimensions.maxwidthTablet}px) {
       margin: 0 auto;
    }

    span {
        margin-left: 1em;
        transform: translateX(-8px);
        display: inline-block;
        transition: transform 400ms ease-in-out;
    }

    &:hover {
        color: ${colors.red};
        transition: all 150ms ease-in-out;

        span {
            transform: translateX(0px);
            opacity: 1;
            transition: transform 150ms ease-in-out;
        }
    }
`

const BlogGrid = styled("div")`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2.5em;
    margin-bottom: 4em;

    @media(max-width: 1050px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1.5em;
    }

    @media(max-width: ${dimensions.maxwidthMobile}px) {
        grid-template-columns: 1fr;
        grid-gap: 2.5em;
    }
`

const RenderBody = ({ home, news, projects, meta }) => (
    <>
        <Helmet
            title={meta.title}
            titleTemplate={`%s | ${meta.title}`}
            meta={[
                {
                    name: `description`,
                    content: meta.description,
                },
                {
                    property: `og:title`,
                    content: meta.title,
                },
                {
                    property: `og:description`,
                    content: meta.description,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `og:image`,
                    content: home.hero_image.url,
                },
                {
                    property: `fb:app_id`,
                    content: meta.fbappid,
                },
                {
                    property: `og:url`,
                    content: meta.siteurl,
                },
                {
                    name: `twitter:card`,
                    content: `summary_large_image`,
                },
                {
                    name: `twitter:creator`,
                    content: meta.author,
                },
                {
                    name: `twitter:title`,
                    content: meta.title,
                },
                {
                    name: `twitter:description`,
                    content: meta.description,
                },
            ].concat(meta)}
        >
            <html lang="lt" />
        </Helmet>
        
        <HomeHeroContainer>
            <div>
                {home.hero_image && (
                            <img src={home.hero_image.url} alt={home.hero_image.alt} />
                    )}
            </div>
        </HomeHeroContainer>

        <Section className="welcome">
            <>  
                {RichText.render(home.hero_title)}
            </> 
            <Link className="joinButton" to="/tapti-nariu">
                    <Button>Tapti bendruomenės nariu</Button>
            </Link>
        </Section>
        
        <Section>
            <h2>Naujienos</h2>
            <BlogGrid>
                {news.map((post, i) => (
                    <PostCard
                        key={i}
                        author={post.node.post_author}
                        category={post.node.post_category}
                        title={post.node.post_title}
                        image={post.node.post_hero_image}
                        date={post.node.post_date}
                        description={post.node.post_preview_description}
                        uid={post.node._meta.uid}
                    />
                ))}
            </BlogGrid>
            <WorkAction to={"/naujienos"}>
                Daugiau naujienų <span>&#8594;</span>
            </WorkAction>
        </Section>

        <Section>
            <h2>Naujausias projektas</h2>
            {projects.map((project, i) => (
                <ProjectCard
                    key={i}
                    category={project.node.project_category}
                    title={project.node.project_title}
                    description={project.node.project_preview_description}
                    thumbnail={project.node.project_hero_image}
                    uid={project.node._meta.uid}
                />
            ))}
            <WorkAction to={"/projektai"}>
                Daugiau projektų <span>&#8594;</span>
            </WorkAction>
        </Section>

        <Section>
            {RichText.render(home.about_title)}
            <About
                bio={home.about_bio}
                socialLinks={home.about_links}
            />
        </Section>
    </>
);

export default ({ data }) => {
    //Required check for no data being returned
    const doc = data.prismic.allHomepages.edges.slice(0, 1).pop();
    const news = data.prismic.allPosts.edges;
    const projects = data.prismic.allProjects.edges;
    const meta = data.site.siteMetadata;

    if (!doc || !projects || !news) return null;

    return (
        <Layout>
            <RenderBody home={doc.node} news={news} projects={projects} meta={meta}/>
        </Layout>
    )
}

RenderBody.propTypes = {
    home: PropTypes.object.isRequired,
    news: PropTypes.array.isRequired,
    projects: PropTypes.array.isRequired,
    meta: PropTypes.object.isRequired,
};

export const query = graphql`
    {
        prismic {
            allHomepages(first: 1) {
                edges {
                    node {
                        hero_title
                        hero_image
                        hero_button_text
                        hero_button_link {
                            ... on PRISMIC__ExternalLink {
                                _linkType
                                url
                            }
                        }
                        content
                        about_title
                        about_bio
                        about_links {
                            about_link
                        }
                    }
                }
            }
            allPosts(first: 2, sortBy: post_date_DESC) {
                edges {
                    node {
                        post_title
                        post_hero_image
                        post_date
                        post_category
                        post_preview_description
                        post_author
                        _meta {
                            uid
                        }
                    }
                }
            }
            allProjects(sortBy:project_post_date_DESC, first:1) {
                edges {
                    node {
                        project_title
                        project_preview_description
                        project_hero_image
                        project_category
                        project_post_date
                        _meta {
                            uid
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
                description
                author
                fbappid
                siteurl
            }
        }
    }
`